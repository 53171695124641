* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

table {
  border-collapse: collapse;
  font-size: 0.8rem;
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
  width: '100%';
  height: '100%';
}

thead,
tbody {
  max-width: 100%;
  max-height: 100%;
}

td,
th {
  border: 1px solid #DCDEE6;
  /* background-color: #FAFAFC; */
  background-color: #FEFEFE;
}

th {
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

  /* background-color: #FAFAFC; */
  background-color: #FEFEFE;
  z-index: 2;
}

.TableWrapper th div {
  height: 62px;
  outline: 1px solid #DCDEE6;
}

td {
  text-align: center;
}

.TableWrapperFixed {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  touch-action: none;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.TableWrapper {
  overflow: scroll;
  touch-action: none;
  position: relative;
  /* border: 1px solid #DCDEE6; */

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

:root {
  --table-width: calc(100vw - 15px - 68px);
  --table-height: calc(100vh - 50px - 48px);
  --column-count: 11;
}

.fixed_table {
  table-layout: fixed;
  width: var(--table-width);
  overflow-x: hidden;


  overflow-y: hidden;
  height: var(--table-height);
}

.fixed_table td>div {
  width: 100% !important;
  height: 100%;
  /* height: 40px; */

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fixed_table td {
  width: calc(var(--table-width) / var(--column-count));
  height: calc(var(--table-height) / var(--column-count) - 1px);
}

.fixed_table .headerStyle{
  width: 50px !important;
}

.table_auto {
  table-layout: auto;
}

.table_auto td>div {
  width: 200px;
  height: 148px;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.THeadStyles {
  /* font-size: 20px; */
  font-weight: bold;
  color: red;
}

.headerStyle {
  z-index: 2;
  /* outline: rgba(138, 146, 158, 1) 1px solid; */

  text-align: center;
}

.headerStyle div {
  color: rgba(138, 146, 158, 1);
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  /* box-shadow: -2px 0px 0px 0px rgb(221, 226, 233); */
  
  /* margin: 0 auto; */
  /* box-shadow: -2px 0px 0px 0px rgb(202, 208, 216); */
}

td.headerStyle div {
  width: 100px;
}