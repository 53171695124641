.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.20);
    ;
    backdrop-filter: blur(3px);
}

.ant-modal-title {
    padding-top: 4px;
    font-size: 22px;
}

.ant-modal-body {
    padding: 8px 24px 24px;
}

.ant-modal-header {
    border-radius: 30px;
    border-bottom: none;
}

.yesActive {
    border: 2px solid rgba(101, 189, 121, 1) !important;
    color: rgba(101, 189, 121, 1) !important;
    background-color: rgba(101, 189, 121, 0.05) !important;
}

.noActive {
    border: 2px solid rgba(231, 70, 70, 1) !important;
    color: rgba(231, 70, 70, 1) !important;
    background-color: rgba(231, 70, 70, 0.05) !important;
}

.ant-radio-button-wrapper {
    span {
        font-weight: 500;
    }
}

textarea {
    font-weight: 500;
}

.modalCode {
    label {
        margin: 10px 0;
    }
}

.modalCodeSave {
    display: flex;
    gap: 10px;
}

.modalCodeGrid {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    width: 70%;
}

.modalCodeInput {
    display: flex;
    gap: 10px;
    width: 105%;
}

@media (max-width: 768px) {
    .ant-modal {
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
        top: 0;
        margin: 0;
        padding: 0;
    }

    .ant-modal-content {
        height: 100% !important;
        border-radius: 0;

    }

    .ant-modal-close {
        color: black;
    }

    .ant-modal-close-x {
        font-size: 24px;
    }

    .ant-modal-header {
        border-radius: 0;
        padding: 16px 10px;
    }

    .ant-modal-body {
        padding: 0px 10px;
        height: 94%;
        position: relative;
    }

    .modalEdit .ant-col {
        flex: 0 0 50%;
        max-width: 100%;
    }

    .modalColStatus {
        flex: 0 0 100% !important;
        width: 100%;
    }

    label {
        font-size: 18px;
    }

    .modalCode {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 10px;
        padding-bottom: 15px;
        background-color: rgba(243, 245, 249, 1);

        label {
            margin-top: 0px;
        }
    }

    .modalCodeGrid {
        width: 100%;

        .ant-input-affix-wrapper-focused {
            background-color: rgba(255, 255, 255, 1);
        }

        span {
            background-color: rgba(255, 255, 255, 1);
        }
    }

    .modalCodeSave {
        flex-direction: column;

        button {
            width: 100%;
            height: 45px;
        }
    }
}

@media (max-width: 360px) {
    .modalEdit .ant-col {
        flex: 0 0 100%;
        max-width: 100%;
    }
}