:root {
    --font-path: 'assets/fonts/';
}

@font-face {
    font-family: 'Gilroy';
    src: url('assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url('assets/fonts/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url('assets/fonts/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url('assets/fonts/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url('assets/fonts/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url('assets/fonts/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
        url('assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url('assets/fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy SemiBold Italic'), local('Gilroy-SemiBoldItalic'),
        url('assets/fonts/Gilroy-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('assets/fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url('assets/fonts/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy ExtraBold Italic'), local('Gilroy-ExtraBoldItalic'),
        url('assets/fonts/Gilroy-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('assets/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url('assets/fonts/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('assets/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url('assets/fonts/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url('assets/fonts/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('assets/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url('assets/fonts/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('assets/fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'), local('Roboto Mono'),
        url('assets/fonts/RobotoMono-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'), local('Roboto Mono'),
        url('assets/fonts/RobotoMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}