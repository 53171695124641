.ant-switch {
    width: 52px;
    height: 32px;
    background-color: rgba(230, 224, 233, 1);
    border: 2px solid rgba(121, 116, 126, 1);
    padding: 0;
}

.ant-switch .ant-switch-handle {
    width: 16px;
    height: 16px;
    top: 6px;
    right: 6px;
}

.ant-switch .ant-switch-handle::before {
    background-color: rgba(121, 116, 126, 1);
}

.ant-switch-checked {
    width: 52px;
    height: 32px;
    background-color: #4B51EA;
    border: none;
}

.ant-switch-checked .ant-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: all 0.2s ease-in-out;
    content: '';
}

.ant-switch-checked .ant-switch-handle {
    width: 24px;
    height: 24px;
    top: 4px;
    left: 4px;
    left: calc(100% - 24px - 4px);
}