@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

.login-container {
    position: absolute;
    z-index: 10;
    width: 100%;
    padding: 30px;
    top: 50%;
    margin-top: -150px;
}

.form-group-default {
    /* background: linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%); */

    position: relative;
    padding: 0;
    overflow: hidden;
    font-weight: normal;
    font-size: 16px;
    /* margin-bottom: 16px; */
    padding: 2px;
    height: 55px;
    display: flex;
    align-items: center;

    border-radius: 20px;
    box-sizing: content-box;
}

.form-control-login {
    background-color: rgba(245, 245, 245, 1);
    padding: 5px 5px 5px 20px;
    height: 100%;
    width: 100%;
    -webkit-appearance: none;
    color: #1c2b36;
    outline: 0;
    /* height: 35px; */
    line-height: normal;
    vertical-align: middle;
    min-height: 35px;
    box-shadow: none;
    font-weight: normal;
    border-radius: 20px;
    border: none;
    box-sizing: content-box;
    font-size: 16px;
}

.form-control::-webkit-input-placeholder {
    color: #8A929E;
    font-weight: 300;
}

.form-control::-moz-placeholder {
    color: #8A929E;
    font-weight: 300;
}

.form-control:-ms-input-placeholder {
    color: #8A929E;
    font-weight: 300;
}

.form-control:-moz-placeholder {
    color: #8A929E;
    font-weight: 300;
}

.form-control:focus {
    outline: 0 !important;
    box-shadow: none;
    background-color: #F3F5F9;
}

.form-control:focus::-webkit-input-placeholder {
    color: #8c9bA6;
}

.form-control:focus::-moz-placeholder {
    color: #8c9bA6;
}

.form-control:focus:-ms-input-placeholder {
    color: #8c9bA6;
}

.form-control:focus:-moz-placeholder {
    color: #8c9bA6;
}


.logo-title-container {
    position: fixed;
    width: max-content;
    bottom: 0px;
    margin-top: -100px;
    left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 32px;
}

.logo {
    height: auto;
    max-width: 52px;
    margin: 0 auto;
    display: inline-block;
}

.logo-container .logo {
    padding: 50px 0px;
}

.copy {
    width: auto;
    display: inline-block;
}

.copy h1 {
    display: block;
    vertical-align: middle;
    color: #fff;
    z-index: 9999;
    position: relative;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    top: 0px;
    line-height: 45px;
    margin: 0 0 0 20px;
    margin-left: 14px;
}

.copy p {
    color: #fff;
    font-size: 13px;
    max-width: 650px;
    opacity: .9;
    position: relative;
    z-index: 99;
    font-weight: 300;
    margin: 0;
    left: 15px;
    position: relative;
    top: -8px;
}

.error-message {
    color: #e74c3c;
    margin: 0;
    margin-left: 11px;
}

.controls {
    display: flex;
    align-items: center;
}

.remember-me-group {
    display: block;
    width: 100%;
    min-height: 1.2em;
    /* margin-bottom: 10px; */
}

.remember-me-text {
    padding: 2px 0 0 6px;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 550;
    cursor: pointer;
}

.remember-me-group input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 10px;
    background-color: rgba(245, 245, 245, 1);
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease, border 0.2s ease;
    position: relative;
}

.remember-me-group input[type="checkbox"]:checked {
    border: 1.5px solid rgba(61, 131, 237, 1);
    background-color: rgba(61, 131, 237, 0.2);
}

.remember-me-group input[type="checkbox"]:checked::after {
    content: url('../../../assets/icons/blueCheckMark.svg');
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ------ */

.white-bg {
    background-color: rgba(245, 245, 245, 1);
    width: 100%;
    height: 100vh;
}

.modalBg {
    background-color: #fff;
    border-radius: 30px;
    padding: 16px;
    /* min-width: 30%;
    max-width: 40%; */
    border: 2px solid rgba(237, 237, 237, 1);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h2 {
        font-weight: 600;
        font-size: 32px;
        font-family: Gilroy;
    }

    h3 {
        font-weight: 500;
        font-size: 18px;
        font-family: Gilroy;
        margin: 0;
        text-align: center;
    }

    form {
        width: fit-content;
    }
}

.loginTitle {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;

    width: 100%;

    text-align: center;
    letter-spacing: 0.002em;

    margin: 0;

    color: #1F2032;
}

.signin {
    width: 100%;
    height: 55px;
    /* padding: 12px 20px; */

    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #fff !important;

    /* background: linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%); */
    background-color: rgba(61, 131, 237, 1);

    border: 0px solid transparent;
    border-radius: 20px;
    transition: 0.5s;
}

.signin:hover,
.signin:focus,
.back-btn:hover,
.back-btn:focus {
    color: #FFF;
    /* background-color: #21A9EE; */
    background-color: rgba(28, 28, 26, 1);
    opacity: 1
}

.err-block {
    padding: 0px;
}

.city-select {
    width: 100%;
    border-radius: 20px;
    height: 55px;
    background-color: rgba(245, 245, 245, 1);

    font-size: 16px;
    color: rgb(157, 157, 155);
}

.city-select .ant-select-selector {
    height: 55px !important;
}

.city-select .ant-select-arrow {
    right: 20px;
}

.city-select .ant-select-selection-item {
    font-weight: 600;
    padding-top: 20px;
}

.custom-dropdown {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.custom-dropdown .ant-select-item {
    padding: 10px 20px; 
    font-size: 16px;
    background-color: transparent;
}

.custom-dropdown .ant-select-item-option-active {
    background-color: rgba(28, 28, 26, 0.7);
}

.custom-dropdown .ant-select-item-option-selected.ant-select-item-option-active {
    background-color: rgba(28, 28, 26, 0.7);
    color: rgba(255, 255, 255, 1);
}

.custom-dropdown .ant-select-item-option-selected {
    background-color: transparent;
    border-radius: 15px;

    color: rgba(61, 131, 237, 1);
}


.city-select-list {
    background: #F0F8FE;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    height: max-content;
}

.city-selectable {
    color: #444656;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    width: 100%;
    height: 24px;

    cursor: pointer;
}

.city-selected {
    color: #2F80ED;
    font-weight: 600;
}

.no-cities-tip {
    color: #b1b3c0;
    font-family: 'Gilroy-Regular';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;

    text-align: center;


    width: 100%;
    height: 24px;
}

.back-btn {
    background: rgba(245, 245, 245, 1);
    color: rgba(28, 28, 26, 1);
    font-weight: 500;
    font-size: 18px;

    width: 100%;
    height: 55px;

    border: none;
    border-radius: 20px;

    transition: 0.7s;
}


@media (max-width: 1500px) {
    .modalBg {
        .modalLogo {
            width: 200px;
        }
    }
}

@media (max-width: 1200px) {
    .form-group-default, .signin, .back-btn, .city-select {
        height: 50px;
    }
}

@media (max-width: 850px) {
    .modalBg {
        .modalLogo {
            width: 170px;
        }
    }
}

@media (max-width: 770px) {
    .remember-me-group input[type="checkbox"]:checked::after {
        position: absolute;
        top: -2px;
        left: 7px;
    }
}

@media (max-width: 430px) {
    .modalBg h3 {
        font-size: 15px;
    }
}

@media (max-width: 380px) {
    .modalBg {
        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 14px;
        }

        .modalLogo {
            width: 150px;
        }
    }

    .remember-me-text {
        font-size: 16px;
    }

    .form-group-default,
    .form-control-login {
        height: 45px;
    }

    .remember-me-group input[type='checkbox'] {
        width: 24px;
        height: 24px;
    }

    .remember-me-group input[type='checkbox']:checked::after {
        width: 21px;
        height: 21px;
        top: -2px;
        left: 1px;
    }

    .signin, .back-btn {
        height: 40px;
        font-size: 15px;
    }

    .form-group-default, .city-select, .city-select .ant-select-selector {
        height: 40px !important;
    }

    .city-select .ant-select-selector {
        font-size: 14px !important;
    }

    .custom-dropdown .ant-select-item {
        padding: 6px 14px; 
        font-size: 14px;
    }
}












/*----------------Loading---------------*/
.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(245, 245, 245, 1);
}

.loading-logo {
    width: 262px;
    margin-bottom: 30px;
}

.loading-progress-bar-container {
    width: 280px;
    height: 12px;
    background-color: rgba(237, 237, 237, 1);
    overflow: hidden;
}

.loading-progress-bar {
    height: 100%;
    width: 0%;
    background-color: rgba(28, 28, 26, 1);
    animation: loading 2s linear forwards;
}

@keyframes loading {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .loading-logo { 
        width: 206px;
    }

    .loading-progress-bar-container {
        width: 210px;
        height: 10px;
    }
}

@media (max-width: 400px) {
    .loading-logo { 
        width: 169px;
        margin-bottom: 20px;
    }

    .loading-progress-bar-container {
        width: 169px;
        height: 10px;
    }
}