.no-border-top {
    border-top: none !important;
    border-top: 1px solid transparent !important;
}

.no-border-bottom {
    border-bottom: none !important;
    border-bottom: 1px solid transparent !important;
}

.no-border-left {
    border-left: none !important;
    border-left: 1px solid transparent !important;
}

.no-border-right {
    border-right: none !important;
    border-right: 1px solid transparent !important;
}