.lounge-view {
    max-height: 50px !important;
    max-width: 50px !important;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    text-align: center;
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    padding: 7px;
    margin: 7px;
    outline: none !important;
    box-shadow: none !important;
}

.lounge-view div {
    display: block !important;
    outline: none !important;
}
.lounge-view__wrapper {
    height: fit-content;
    width: fit-content;
    box-shadow: none !important;
}

.lounge-view__text {
    font-size: 14px;
    font-weight: 600;
    box-shadow: none !important;
    line-height: 100%;
}