@import '~antd/dist/antd.css';
@import 'fonts.css';

:root {
  --b: #262626;
  --w: #dcdcd2;
  --s: #cccccc40;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy';
}

p {
  margin: 0 !important;
}

/* Scrollbar */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #bdbdbd #F3F5F9;

  /* overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.first-plan-object {
  z-index: 6000;
}

html,
body {
  height: 100vh;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}


.ant-layout {
  height: 100%;
  /* background-color: #FAFAFC; */
  background-color: #fefefe;
}

/* ------------DRAGGING BORDER ----------- */

.dragging {
  background-image: linear-gradient(90deg, #448ef7 50%, transparent 50%), linear-gradient(90deg, #448ef7 50%, transparent 50%), linear-gradient(0deg, #448ef7 50%, transparent 50%), linear-gradient(0deg, #448ef7 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 4px, 20px 4px, 4px 20px, 4px 20px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 0.5s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }

  100% {
    background-position: left 20px top, right 20px bottom, left bottom 20px, right top 20px;
  }
}

.selectedCell {
  background-image: linear-gradient(90deg, #448ef7 50%, transparent 50%), linear-gradient(90deg, #448ef7 50%, transparent 50%), linear-gradient(0deg, #448ef7 50%, transparent 50%), linear-gradient(0deg, #448ef7 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 4px, 20px 4px, 4px 20px, 4px 20px;
  background-position: left top, right bottom, left bottom, right top;
  position: relative;
  z-index: 1;
}

/* Селектор*/

.ant-modal-content {
  border-radius: 15px;
}

.ant-btn {
  border-radius: 10px;
}

.ant-select {
  height: 100%;
}

/* стили для неактивных option в селекте */
.disabled-option {
  color: rgba(95, 94, 94, 0.25) !important;
}

.disabled-option:hover {
  color: rgba(60, 60, 60, 0.25) !important;
  background-color: rgba(163, 158, 158, 0.25) !important;
}


.default-select.ant-select-status-error {
  background-color: rgba(252, 91, 86, 0.1);
  border: 2px solid #FC5B56;
  color: #FC5B56;
  border-radius: 15px;
}

.default-select.ant-select-status-error * {
  background-color: transparent !important;
}

.default-select:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: #F3F5F9;
}

.default-select:focus,
.ant-select-focused {
  border-color: #40a9ff;
  border-radius: 15px;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {

  width: 100%;
  max-height: 52px;
  /* height: 100%; */
  height: 40px;
  /* height: 52px !important; */
  background: #F3F5F9;
  border-radius: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.002em;
  color: #444656;
  display: flex;
  align-items: center;
  border: none;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100%;
  position: inherit
}

.ant-select-dropdown {
  background: #F3F5F9;
  border-radius: 15px;
  padding: 0;
}

.ant-select-item {
  cursor: pointer;
  padding: 14px 16px 14px 24px;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.002em;
  color: #444656;
  transition: all 0.5s ease;
}

.ant-select-item:hover {
  background: #444656;
  border-radius: 15px;
  color: #F3F5F9;
}

/* Drawer antd */

.ant-drawer-title .ant-radio-button-wrapper {
  /* font-size: 12px; */
  padding: 12px 1px;
}

.ant-notification-notice-message {
  margin-top: 2px;
}

.ant-notification-notice-description {
  margin-left: 0;
}

.ant-notification-notice-icon {
  margin-left: 0;
}

/* Ячейки */
.cell__container {
  background-color: transparent;
  overflow: scroll;
  height: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;

  /* justify-content: center; */

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cell__container .ant-select-selection-search-input {
  display: none;
}


.cell__container .cell__header,
.cell__container .cell__wrapper {
  padding: 0.4em 1em;
}


.cell__header {
  font-weight: 700;
  font-size: 0.9em;
  line-height: 120%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;

  padding: 0.4em !important;
  min-height: 37px !important;
  /* max-height: 54px !important; */
}

.cell__header>div:first-child {
  padding-left: 3px;
  max-width: 77px;
  /* width: 90px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 2;
}

.cell__wrapper .cell__description {
  margin-top: 3px;
  font-size: 14px;
  text-align: start;
  -webkit-line-clamp: 3;
  display: -webkit-box; 
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cell__wrapper .cell__description span{
  font-weight: 500;
  margin-right: 4px;
}

.booked-item {
  cursor: pointer;
}

.booked-item__title {
  font-weight: 600;
  font-size: 1em;
  line-height: 120%;
  margin: 0;
  letter-spacing: -0.41px;
  text-transform: uppercase;
  color: #050411;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.booked-item__payment-status {
  border-radius: 25px;
  padding: 4px;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.002em;

  margin-top: 5px;
}

.table-item__payment-status, .table-item__payment-type {
  border-radius: 16px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  font-size: 13px;
  height: 28px;
  color: #fff;
  text-align: start;

  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  img {
    padding-bottom: 1px;
  }
}

@container (width < 200px) {
  .cell__container {
    font-size: 12px !important;
    flex-direction: row;
  }
}

/*Календарь*/

td {
  padding: 0;
}



.date-picker-input {
  background: #F3F5F9;
  border-radius: 15px;
  border: none;
  height: 52px;
}

.ant-picker-input {
  flex-direction: row-reverse;
  gap: 18px;
}

.date-picker-input input {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.002em;
  color: #444656;
}

.ant-picker-panel-container {
  background: #F3F5F9;
  border-radius: 15px;
  padding: 24px;
  width: 538px;
  height: 373px;
}

.ant-picker-footer {
  width: auto;
  min-width: fit-content;
  flex-grow: 3;
}


.ant-picker-panel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border: none;
}

.ant-picker-date-panel {
  width: auto;
  height: 100%;
  flex-grow: 2.5;
}

.ant-picker-date-panel * {
  padding: 0;
  margin: 0;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 0;
  height: 100%;
}

.ant-picker-header-view {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #596982;
}

.ant-picker-content {
  width: 100% !important;
  height: 100%;
  margin: 0;
  table-layout: auto
}

.ant-picker-cell {
  color: #A0A9B7;
}

.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}

.ant-picker-cell-in-view {
  color: #12284C;
}

.nav-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.nav-date {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #444656;
  cursor: pointer;
  touch-action: manipulation;
}

.nav-date.active {
  color: #30A5D1;
}

.date-cell {
  height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-cell.active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #30A5D1;
  border-radius: 4px;
  color: #F3F5F9;
}

/*Таблица*/

.room-name {
  color: #000000 !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.002em;
  padding: 5px;
}

.glasses-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.013em;
  color: #8b929d;
}




/* --Ячейки */
.table__cell {
  height: 100%;
  overflow: scroll;
}


.table__cell.selectable {
  /* background-color: #e9ffef; */
  background-color: #7a7edb;
}

.table__cell.unselectable {
  background-color: #888faa;
}

.table__cell.unselectable--idle {
  /* background-color: #ededed; */
  /* opacity: 0.4 */
  background-color: #e6e6e6;
  opacity: 0.50
}

.table__cell.selected {
  background-color: #FFE9EF;
}



/* Popover */

/*Переопределени стилей wrapper*/

.ant-popover-inner {
  min-width: 348px;
  background: #FFFFFF;
  border: none !important;
  box-shadow: 0px 0px 20px #0000001A;
  border-radius: 15px;
}

.smallerPopover .ant-popover-inner {
  width: 300px !important;
  background: #FFFFFF;
  border: none !important;
  box-shadow: 0px 0px 20px #0000001A;
  border-radius: 15px;
}

.ant-popover-inner-content {
  padding: 24px;
}

.smallerPopover .ant-popover-inner-content {
  padding: 12px !important;
}

.popover__section {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.popover__title {
  color: #444656;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.popover__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  border: 1px solid #444656;

  img {
    width: 20px;
    height: 20px;
  }
}

.send {
  background-color: rgba(101, 189, 121, 0.2);
  border: none !important;
}

.cancel {
  background-color: rgba(231, 70, 70, 0.2);
  border: none !important;
}

.popover__info_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 16.8px;
  color: #444656;
  margin-bottom: 6px;
}

.popover__info_text {
  font-weight: 400;
  font-size: 20px;
  line-height: 22.4px;
  color: #444656;
  word-break: break-all;
}

.payment-status {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.002em;
}

.payment-status.paid {
  color: #65BD79;
}

.payment-status.notpaid {
  color: #EE6E7E;
}

/*Общие*/
.default-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.002em;
  text-align: center;
  color: #FFFFFF;
  padding: 0px 32px;
  height: 46px;
  /* background: linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%); */
  background-color: #4B51EA;
  border-radius: 15px;
}

.default-btn-danger {
  background-color: #FF4D4F;
  width: 100%;
}
.default-btn-danger:hover {
  color: #FF4D4F;
  border: 1px solid #FF4D4F;
  background-color: #fff;
}
.default-btn-grey {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.002em;
  text-align: center;
  color: #FFFFFF;
  padding: 0px 32px;
  height: 46px;
  /* background: linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%); */
  background-color: #444656;
  border-radius: 15px;
}

.default-btn-grey:hover {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.002em;
  text-align: center;
  color: #444656;
  padding: 0px 32px;
  height: 46px;
  /* background: linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%); */
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #444656;
}

/* .ant-btn:hover, .ant-btn:focus, .ant-btn:active{
  background: #EBECF0 ;
}

.ant-btn:hover, .ant-btn:focus{
  background: #EBECF0 ;
} */

/* -------------------------------SIDER--------------------------- */
.creating-sider {
  background-color: #FFFFFF;
}

.sider-content-wrapper {
  padding: 16px 40px;


  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* ---SIDER-FORM----- */
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0;
}

.order-id {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #1F2032;
}

.ant-form-item {
  margin-bottom: 10px;
}

.creation-form-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #444656;
  margin-bottom: 10px;
}

/* ---INPUT------ */
.default-input {
  border-radius: 15px;
  padding: 11px 16px;
  background-color: #F3F5F9;

  padding: 9px 12px;
}

.default-input:active {
  background-color: transparent;
}


.default-input.ant-input-status-error,
.default-input.ant-input-affix-wrapper-status-error {
  background-color: rgba(252, 91, 86, 0.1);
  border: 2px solid #FC5B56;
  color: #FC5B56;
}

.default-input:hover,
.default-input:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: #F3F5F9;
}

.default-input:focus,
.ant-input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.default-input::selection {
  border: 2px solid #2F80ED;
}

/* -----EMPLOYEE INPUT */

.employee-input::placeholder {
  color: #2F80ED;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.002em;
}

/* -----BONUS-SLIDER */
.bonus-slider .ant-slider-handle {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -25%) !important;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%) border-box;
  border-radius: 50em;
  border: 3px solid transparent;
}

.bonus-slider .ant-slider-rail {
  background: linear-gradient(90deg, rgba(54, 192, 231, 0.5) 6.68%, rgba(75, 81, 234, 0.5) 84.84%, rgba(75, 81, 234, 0.5) 87.36%);
  border-radius: 5px;
}

/* ----BONUS-CHECKBOX----- */
.bonus-checkbox:hover * {
  border: none
}

.bonus-checkbox .ant-checkbox-inner {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%) border-box;
  border-radius: 50em;
  border: 3px solid transparent;
}

.bonus-checkbox .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 24px;
  height: 24px;
}

.bonus-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #2F80ED;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-25%, -50%);
}


.bonus-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.bonus-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.bonus-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner .bonus-checkbox .ant-checkbox-checked::after {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%) border-box;
  border-radius: 50em;
  border: 3px solid transparent;
}


.bonus-checkbox .ant-checkbox-checked::after {
  border-radius: 50%;
}

/* ADD BUTTON */

.gradient-border {
  border-radius: 16px;
  background: linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%);
  padding: 2px;
}

.add-btn {
  background-color: #F0F8FE !important;
  border-radius: 14px;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Certificate tag */
.certificate-tag {
  background-color: #F091AA;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.002em;
  color: #FFFFFF;
  padding: 8px 16px;
}

.certificate-tag .anticon.anticon-close.ant-tag-close-icon {
  margin-left: 25px;
  color: #FFFFFF;
  font-size: 16px;
}



/* ----RADIO BUTTON-- */
.ant-radio-button-wrapper {
  box-sizing: border-box;
  padding: 12px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.002em;
  color: #8A929E;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F3F5F9;
  border-radius: 15px !important;
  border: 2px solid transparent;
}

.ant-radio-button-wrapper:first-child {
  border: 2px solid transparent
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #2F80ED;
  background-color: #F0F8FE;
  border: 2px solid #2F80ED;
}

/* ---------BOOKING-FORM---------------*/

.bookings-wrapper,
.price-wrapper {
  padding: 20px;
  background-color: #F3F5F9;
  border-radius: 15px;
  overflow: auto;
  touch-action: none;
}

.booking-form {
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 270px;
  min-height: 270px;
}

.booking-form__header {
  border-radius: 10px 10px 0px 0px;
  height: 40px;
  padding: 0 14px;
}

.booking-form__wrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 20px;
}

/* -----SWITCH------ */
.loyalty-switch {
  box-sizing: border-box;
  height: 30px;
  width: 110px;
  background: linear-gradient(#F3F5F9, #F3F5F9) padding-box,
    linear-gradient(90deg, #36C0E7 6.68%, #4B51EA 84.84%, #4B51EA 87.36%) border-box;
  border-radius: 50em;
  border: 3px solid transparent
}

.loyalty-switch .ant-switch-handle {
  top: 3px
}

.loyalty-switch .ant-switch-handle::before {
  border: 2px solid #4B51EA;
}

.loyalty-switch .ant-switch-inner {
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #444656;
}

/**/
.game-type-container {
  /* border-radius: 15px; */
  text-align: center;
  align-self: center;
  /* padding: 10px 14px; */
  padding: 15px 14px;
  border: 1px solid #DCDEE6;
  /* color: #8A929E; */
  color: #1C1C1A;

  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 15px;
  width: 150px;
}

.game-type__color {
  height: 10px;
  width: 10px;
  margin-right: 8px;
  border-radius: 50%;
}

.input-error {
  color: #bf1650;
  font-size: 12px;
}

.input-warn {
  color: #ffa809;
  font-size: 12px;
}

/* ---------------------PRICE GROUP------------------------- */

.price-container {
  position: relative;
}

.price-message {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ededed;
  opacity: 0.4;
}

.price-intermediate-group {
  border-bottom: 1px solid #444656;
}

.price-intermediate-value-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #444656;
}

.price-intermediate-value-title.named {
  font-size: 12px;
}

.price-intermediate-value.name {
  font-size: 12px;
}

.price-intermediate-value {
  font-weight: 600;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #1F2032;
}

.price-value-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #1F2032;
}

.price-value {
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.002em;
  color: #1F2032;
}

.certificate-input-label {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.certificate-input-error {
  height: 1em;
  width: 100%;
  bottom: -1.1em;
  position: absolute;
  padding-left: 10px;
}