/* * {
    cursor: none;
  } */

.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cursor-dot {
  width: 8px;
  height: 8px;
  background-color: #dc5656;
  z-index: 999;
}

.cursor-dot-outline {
  width: 40px;
  height: 40px;
  /* background-color: rgba(229, 128, 128, 0.5); */
  z-index: 999;
}

.links-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
}